<template>   
   <Content404 /> 
</template>

<script>
// Importando los componententes pertenecientes a esta vista'
import Content404 from '@/components/login/Content404'
export default 
{
  name: '404',
  components: 
  {
    Content404
  }
}
</script>

<style>


</style>

export const filters = (app) => 
{
app.config.globalProperties.$filters = 
  {
    shortTime(value) 
    {
        const split = value.split(':')
        return split[0]+':'+split[1];
    },
    OnlyLetters(value) 
    {
        const string = value.toString().replace(/^\s+|\s+$/g,'').replaceAll(',', '\n')
        return string
    },
    UnixToDateHours (value)
    {
      const date = new Date(value*1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      return date
    }
  }
}
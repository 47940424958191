<template>
  <main class="col-md-12 ms-sm-auto col-lg-12 px-md-4  align-items-center justify-content-center">
    <div class="container">
      <div class="row row-cols-1 row-cols-md-3 mb-3 text-center justify-content-center align-items-center minh-100">               
        <div class="col-12">
          <img class="img-fluid" src="@/assets/img/404.png" width="300" alt="">
          <div align="center">
            <h1 class="h1-404-2">404 Not Found</h1>
            <h2>Page not found</h2>
            <p>
              We locked everywhere for this page. <br> 
              Are you sure the website URL is correct?<br>
              Get in touch with the site owner
            </p>
            <div class="col-md-3">
              <a class=" button-1 w-100 btn btn-xs btn-primary" @click="$router.go(-1)"> Go Back</a>
            </div>
          </div> 
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default
  {
    name: 'Content404'     
  }
</script>

<style></style>

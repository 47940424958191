<template>
  <div class="content">
    <main class="flex-shrink-0 form-signin">
      <div class="content p-4 box-login shadow">
        <form @submit.prevent="Login()" method="post">
          <img class="mb-0" :src="require('@/assets/img/login.png')" style="width:30%;">
          <br>
          <img class="mb-1" :src="require('@/assets/img/brand.png')" style="width:50%;">
          <h4 class="text-color-1 mb-3 fw-bold">Sign In to continue</h4>
          <div class="text-start" v-if="DisableInputs.disabled != 'false'" >
            <label class="text-color-1">Email</label>
            <input   type="email" v-model="login.email" class="form-control" id="email" placeholder="Email" required="required"  >
          </div>
          <div class="text-start" v-if="DisableInputs.disabled != 'false'" >
            <label class="text-color-1">Password</label>
            <input type="password" v-model='login.password' class="form-control" id="password" placeholder="Password" required="required">
          </div>
          <div class="text-start" style="display:none;">
            <label class="text-color-1">Type</label>
            <input type="text" v-model='login.type' class="form-control" id="type" placeholder="Type">
          </div>
          <div class="text-start">
            <Multiselect v-if="MultiSelectType.status != 'false'" v-model="MultiSelectType.value" v-bind="MultiSelectType" :change="SetType()" ref="multiselect" class="size-multiselect"></Multiselect>
          </div>
           <hr>
          <button :disabled="Form.submit" class="button-1 w-100 btn btn-primary" type="submit">Sign in</button>
          <hr>
        </form>
        <div class="container">
          <div class="row" v-if="DisableInputs.disabled != 'false'" >
            <div class="col-12">
              <div class="border-btn btn-google">
                <a :href="Google.link" v-on:click="Google.click = 1">
                  <span class="icon-google">
                    <img src="@/assets/img/google.png" width="15" height="15" alt="">
                  </span>
                  <span class="text-w"> Sign in With Google </span>
                </a>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="border-btn btn-drive">
                <a :href="Microsoft.link" class="">
                  <span class="icon-drive">
                    <img src="@/assets/img/microsoft.png" width="15" height="15" alt="">
                  </span>
                  <span class="text-w"> Sign in With Microsoft </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="checkbox mb-1 mt-3">
          <span class="text-color-1 fw-bold">
            <router-link to="/forgot" class="link-color fw-bold">Forgot Password?</router-link>
          </span>
        </div>
        <div class="checkbox mb-1"></div>
        <span class="text-color-1">Don’t have any account? <router-link to="/signup" class="link-color fw-bold">Register</router-link>
        </span>
      </div>
    </main>
    <p class="mt-0 text-muted">© Work Mileage 2022 </p>
  </div>
</template>
<script>
import Api from '@/services/Api';
import axios from "axios";
import URL from '@/services/Request.js';
import Multiselect from '@vueform/multiselect'

  export default 
  {
    components: 
    {
      Multiselect,
    },
    data: () => (
    {
      LoginUrl: 
      {
        endpoint: "/login"
      },
      login: 
      {
        email: "",
        password: "",
        type: ""
      },
      MultiSelectType: 
      {
        status:"false",
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        hideSelected: true,
        showOptions: true,
        required: true
      },

      response: 
      {
        message: "",
        status: ""
      },
      Form: 
      {
        submit: false
      },
      Google:
      {
        link: "#",
        click: "",
      },
      Microsoft:
      {
        link: "#",
      },
      DisableInputs:
      {
      disabled: "true",
      }
    }),
    methods: 
    {
      SetType()
      {
        this.login.type = this.MultiSelectType.value
      },
      Login() 
      {
        this.Form.submit = true

        Api.post(this.LoginUrl.endpoint,this.login).then((result) => 
        {
          this.response.message =  result.data.msg
          this.response.status = result.data.status

          if(this.response.status == "false")
          {
            this.$moshaToast( ''+this.response.message+'', { type: 'default', position:'bottom-right', transition:'zoom' })

            setTimeout( () => 
            {
              this.Form.submit = false
            }, 5000);
          }

          if(this.response.status == "true")
          {

            if(result.data.types)
            {
              let mytypes = [];

              for (var i = 0; i < result.data.types.length; i++) 
              {
                mytypes.push(
                {
                  value: result.data.types[i].type,
                  label: result.data.types[i].description
                });
              }

               mytypes.push(
                {
                  value: '',
                  label: result.data.msg
                });
    
              this.MultiSelectType.options = mytypes;
              this.MultiSelectType.status = "true"
              this.Form.submit = false
              return
             
            }

            this.response.message = "Welcome, Successful Login."

            this.$moshaToast( ''+this.response.message+'', { type: 'default', position:'bottom-right', transition:'zoom' })
            this.Form.submit = false
     
            //var sessionStore = JSON.stringify({ names: result.data.user.names, picture: result.data.user.picture})
            //localStorage.setItem('session', sessionStore)
            localStorage.setItem('names', result.data.user.names)
            localStorage.setItem('picture', result.data.user.picture)

            this.$store.commit("setAuthentication", true)
            this.$store.commit("setTypeUser", result.data.user.type)
            this.$store.commit("setToken", result.data.token.access_token)
            this.$store.commit("setRefreshToken", result.data.token.refresh_token)
            this.$store.commit("setModules", result.data.user.modules)

            if(result.data.user.type=='0')
            {
              this.$router.push({ name: "Team-Dashboard" });
              //setTimeout( () => this.$router.push({ path: '/dashboard'}), 3000);´
            }

            if(result.data.user.type=='1')
            {
              this.$router.push({ name: "Team-Dashboard" });
              //setTimeout( () => this.$router.push({ path: '/dashboard'}), 3000);´
            }

            if(result.data.user.type=='2')
            {
              this.$router.push({ name: "Worker-Dashboard" });
              //setTimeout( () => this.$router.push({ path: '/dashboard'}), 3000);´
            }
            
            if(result.data.user.type=='3')
            {
              this.$router.push({ name: "Manager-Dashboard" });
              //setTimeout( () => this.$router.push({ path: '/dashboard'}), 3000);´
            }
          }
        })
      },
      LinkGoogle() 
      {
        Api.get("/login/social/google").then((result) => 
        {
          if(result.data.status == "true")
          {
            this.Google.link = result.data.url

            if(this.$route.query.provider == 'google')
            {
              window.location.href =  this.Google.link
            }
          }
        })
      
        if(this.$route.query.scope)
        {
          if(this.$route.query.code)
          {
            const FromApp = JSON.parse(localStorage.getItem('FromApp'))

            if(FromApp == null)
            {

              Api.get('/login/social/google/callback?code=' + this.$route.query.code).then((result) => 
              {
                
                if(result.data.status == "true")
                {

                  if(result.data.types)
                  {
                     this.DisableInputs.disabled = "false"
                     this.LoginUrl.endpoint = "/login/social/check"
                     this.login.email =  result.data.email
                     this.login.password=  result.data.code

                    let mytypes = [];

                    for (var i = 0; i < result.data.types.length; i++) 
                    {
                      mytypes.push(
                      {
                        value: result.data.types[i].type,
                        label: result.data.types[i].description
                      });
                    }

                    mytypes.push(
                      {
                        value: '',
                        label: result.data.msg
                      });
          
                    this.MultiSelectType.options = mytypes;
                    this.MultiSelectType.status = "true"
                    this.Form.submit = false
                    return
              
                  }  

                 this.$store.commit("setToken", result.data.token.access_token)
                 this.$store.commit("setRefreshToken", result.data.token.refresh_token)
                 this.$router.push({ path:'/login/Dashboard/'+result.data.token.access_token+'' } )

                } 
              })
            }
            else
            {
              localStorage.clear();
              window.location.href = 'http://localhost:'+FromApp.port+'?code='+this.$route.query.code+''
            }
          }
        }
      },
      LinkMicrosoft() 
      {
        Api.get("/login/social/microsoft").then((result) => 
        {
          if(result.data.status == "true")
          {
            this.Microsoft.link = result.data.url

            if(this.$route.query.provider == 'microsoft')
            {
              window.location.href =  this.Microsoft.link
            }
          }
        })
      
        if(!this.$route.query.scope)
        {
          if(this.$route.query.code)
          {
            const FromApp = JSON.parse(localStorage.getItem('FromApp'))

            if(FromApp == null)
            {

              Api.get('/login/social/microsoft/callback?code=' + this.$route.query.code).then((result) => 
              {
                if(result.data.status == "true")
                {

                 
                if(result.data.status == "true")
                {

                  if(result.data.types)
                  {
                     this.DisableInputs.disabled = "false"
                     this.LoginUrl.endpoint = "/login/social/check"
                     this.login.email =  result.data.email
                     this.login.password=  result.data.code

                    let mytypes = [];

                    for (var i = 0; i < result.data.types.length; i++) 
                    {
                      mytypes.push(
                      {
                        value: result.data.types[i].type,
                        label: result.data.types[i].description
                      });
                    }

                    mytypes.push(
                      {
                        value: '',
                        label: result.data.msg
                      });
          
                    this.MultiSelectType.options = mytypes;
                    this.MultiSelectType.status = "true"
                    this.Form.submit = false
                    return
              
                  }  
                }
              
                  this.$store.commit("setRefreshToken", result.data.token.refresh_token)
                  this.$router.push({ path:'/login/Dashboard/'+result.data.token.access_token+'' } )

               
                }
              })
            }
            else
            {
              localStorage.clear();
              window.location.href = 'http://localhost:'+FromApp.port+'?code='+this.$route.query.code+''
            }
          }
        }
      },
      AppGet()
      {
        if(this.$route.query.app == '1')
        {
          var FromAppStore = JSON.stringify({ value: this.$route.query.app, provider: this.$route.query.provider, port: this.$route.query.port,})
          localStorage.setItem('FromApp', FromAppStore)
        }
      }
    },
    mounted()
    {
      this.AppGet()
      this.LinkGoogle() 
      this.LinkMicrosoft() 
    }
  }
</script>

<style scoped>
.link-color
{
  color: var(--color-three);
  text-decoration: none;
}
.content 
{
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.box-login
{
    background-color: var(--color-two);  
    border-radius: 5px;  
}
.text-color-1
{
    color:var(--color-one);
}
.form-signin 
{
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.button-1
{
    background-color: var(--color-one);
    border:1px solid var(--color-one);
}
.button-1:hover
{
    background-color: var(--color-one);
    border:1px solid var(--color-one);
}

.form-signin .checkbox 
{
  font-weight: 400;
}

.form-signin input[type="text"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}

.form-signin input[type="email"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}

.form-signin input[type="password"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}
.bd-placeholder-img 
{
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) 
  {
    .bd-placeholder-img-lg 
    {
      font-size: 3.5rem;
    }
  }

.h4, h4 
{
    font-size: calc(1rem + .3vw);
}
/* alert style */
.alert-success {
    color: #0f5132;
    background-color: #f8f9fa;
    border-color: #badbcc;
}
.alert-danger {
    color: #842029;
    background-color: #f8f9fa;
    border-color: #f5c2c7;
}
.alert-warning {
    color: #664d03;
    background-color: #f8f9fa;
    border-color: #ffecb5;
}
.alert-primary {
    color: #084298;
    background-color: #f8f9fa;
    border-color: #b6d4fe;
}
.alert-light {
    color: #636464;
    background-color: #f8f9fa;
    border-color: #fdfdfe;
}
.alert-info {
    color: #055160;
    background-color: #f8f9fa;
    border-color: #b6effb;
}
.alert-secondary {
    color: #41464b;
    background-color: #f8f9fa;
    border-color: #d3d6d8;
}
</style>

import axios from 'axios';
const Api  = axios.create
({
  //baseURL: 'http://localhost:8000',
  //baseURL: 'https://ex8fkdh2oa.execute-api.us-east-2.amazonaws.com',
  baseURL: process.env.VUE_APP_ENDPOINT,
  headers: { 'Content-Type': 'application/json',}
})


export default Api

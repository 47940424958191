<template>
<router-view>
</router-view>
</template>

<style>
*{
    box-sizing: border-box;
  }
html, body
{
  background-color: var(--color-four)!important; 
  height: 100%; 
}
:root 
{
    --color-one: #15588E;
    --color-two: #ffffff;
    --color-three: #323232;
    --color-four: #F4F4F4;
    --color-five: #E9E9EB;    
} 
#app 
{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.vel-img-title {
  font-size: 120% !important;
  background-color: black !important;
  font-weight: bold;
  padding: 10px !important;
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {store} from '@/store/store.js' 
import {getRole} from "@/router/middleware/auth.js";
import { filters } from '@/services/Filters.js'
import "@/assets/css/global.css"
/* charts */
import VueApexCharts from "vue3-apexcharts";


//Lightbox
import VueEasyLightbox from 'vue-easy-lightbox'

//Toast
import moshaToast from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'

//DatePicker
import VCalendar from 'v-calendar';

///createApp(App).use(router).mount('#app')
const app = createApp(App)
app.use(VueApexCharts)
app.use(router)
app.use(store)
app.use(VueEasyLightbox)
app.use(moshaToast)
app.use(VCalendar)
app.config.globalProperties.$getRole = getRole
filters(app)
app.mount('#app')


import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls"

var ls = new SecureLS({ isCompression: false });

export const store = new Vuex.Store(
    {
        state: 
        {
            Authenticated: false,
            TypeUser: "",
            Token: "",
            RefreshToken: "",
            BlockModules: "",
            Online: ""
        },
        mutations: 
        {
            setAuthentication(state, status) 
            {
                state.Authenticated = status;
            },
            setTypeUser(state, status) 
            {
                state.TypeUser = status;
            },
            setToken(state, status) 
            {
                state.Token = status;
            },
            setRefreshToken(state, status) 
            {
                state.RefreshToken = status;
            },
            setModules(state, status) 
            {
                state.BlockModules = status;
            },
            setOnline(state, status) 
            {
                state.OnlineStatus = status;
            },
        
          
        
        },
        plugins: [createPersistedState({
              storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
              },
            }
        )],
    
    }

    
);
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/main/Home.vue'
import PageNotFound from '../views/main/404.vue'

import {store} from "@/store/store.js";
import Api from '@/services/Request';


import { ifAuth, ifNotAuth, getRole, Type, BlockModule  } from "./middleware/auth"


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: PageNotFound 
    
  },
  /******************** Vistas (Organization/Admin) ***************************/
  {
    path: "/dashboard/team",
    name: "Team-Dashboard",
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/index.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: '/dashboard/team/404',
    name: 'Team-404',
    meta: { authorize: [Type[0],Type[3]] },    
    component: () => import('../views/team/dashboard/modules/404/404.vue')
  },
  {
    path: '/dashboard/team/upgrade',
    name: 'Team-Upgrade',
    meta: { authorize: [Type[0],Type[3]] },    
    component: () => import('../views/team/dashboard/modules/upgrade/Upgrade.vue')
  },
 
  {
    path: '/dashboard/team/member',
    name: 'Team-Member',
    meta: { authorize: [Type[0],Type[3]] },    
    component: () => import('../views/team/dashboard/modules/users/member/Member.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: '/dashboard/team/organization',
    name: 'Team-Organization',
    meta: { authorize: [Type[0],Type[3]] },    
    component: () => import('../views/team/dashboard/modules/users/organization/Organization.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
 
  {
    path: "/dashboard/team/subscription",
    name: "Team-Subscription",
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/subscription/Subscription.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: "/dashboard/team/plan",
    name: "Team-Plan",
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/plan/Plan.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: "/dashboard/team/payroll",
    name: "Team-Payroll",
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/payroll/Payroll.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: "/dashboard/team/report",
    name: "Team-Report",
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/report/Report.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: "/dashboard/team/report/time",
    name: "Team-Time",
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/report/time/Time.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: "/dashboard/team/report/appurl",
    name: "Team-AppUrl",
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/report/appurl/AppUrl.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: "/dashboard/team/report/payments",
    name: "Team-ReportPayments",
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/report/payments/Payments.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: "/dashboard/team/report/amounts",
    name: "Team-ReportAmountsOwend",
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/report/payments/Amounts.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: "/dashboard/team/report/weekly",
    name: "Team-Weekly",
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/report/weekly/Weekly.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: '/dashboard/team/addorganization',
    name: 'Team-AddOrganization',
    meta: { authorize: [Type[0],Type[3]] },    
    component: () => import('../views/team/dashboard/modules/users/organization/AddOrganization.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: '/dashboard/team/addmember',
    name: 'Team-AddMember',
    meta: { authorize: [Type[0],Type[3]] },    
    component: () => import('../views/team/dashboard/modules/users/member/AddMember.vue'),
    beforeEnter: [ifAuth,BlockModule],
  },
  {
    path: '/dashboard/team/editmember',
    name: 'Team-EditMember',
    meta: { authorize: [Type[0],Type[3]] },   
    component: () => import('../views/team/dashboard/modules/users/member/EditMember.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
   /* teams o equipos de trabajo*/
  {
    path: '/dashboard/team/team',
    name: 'Team-Team',
    meta: { authorize: [Type[0],Type[3]] },   
    component: () => import('../views/team/dashboard/modules/team/Team.vue'),
    beforeEnter: [ifAuth,BlockModule]
  }, 
  {
    path: '/dashboard/team/team/edit/:id',
    name: 'ContentEditTeam',
    meta: { authorize: [Type[0],Type[3]] },  
    component: () => import('../views/team/dashboard/modules/team/EditTeam.vue'),
    beforeEnter: [ifAuth,BlockModule]
  }, 
  {
    path: '/dashboard/team/team/addmember/:id',
    name: 'AddMember-Team',
    meta: { authorize: [Type[0],Type[3]] },  
    component: () => import('../views/team/dashboard/modules/team/AddMember-Team.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  /* end team */
  /* Project */
  {
    path: '/dashboard/team/project',
    name: 'Team-Project',
    meta: { authorize: [Type[0],Type[3]] },  
    component: () => import('../views/team/dashboard/modules/project/Project.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: '/dashboard/team/project/edit/:id',
    name: 'ContentEditProject',
    meta: { authorize: [Type[0],Type[3]] },  
    component: () => import('../views/team/dashboard/modules/project/EditProject.vue'),
    beforeEnter: [ifAuth,BlockModule]
  }, 
  {
    path: '/dashboard/team/project/addmember/:id',
    name: 'AddMember-Project',
    meta: { authorize: [Type[0],Type[3]] },  
    component: () => import('../views/team/dashboard/modules/project/AddMember-Project.vue'),
    beforeEnter: [ifAuth,BlockModule]
  }, 

  /* End Project */

  /* ToDo*/
  {
    path: '/dashboard/team/todo',
    name: 'Team-ToDo',
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/to-do/ToDo.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: '/dashboard/team/todo/edit/:id',
    name: 'ContentEditTodo',
    meta: { authorize: [Type[0],Type[3]] },  
    component: () => import('../views/team/dashboard/modules/to-do/EditTodo.vue'),
    beforeEnter: [ifAuth,BlockModule]
  }, 
  /* TimeSheet*/
  {
    path: '/dashboard/team/timesheet',
    name: 'Team-TimeSheet',
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/timesheet/TimeSheet.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
   /* Profile*/
   {
    path: '/dashboard/team/setting/profile',
    name: 'Team-Profile',
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/setting/profile/Profile.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  
  /* Screenshoot*/ 
  {
    path: '/dashboard/team/screenshot',
    name: 'Team-Screenshot',
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/activity/screenshot/Screenshot.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  /* Url */
  {
    path: '/dashboard/team/url',
    name: 'Team-Url',
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/activity/url/Url.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  /* App */
  {
    path: '/dashboard/team/app',
    name: 'Team-App',
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/activity/app/App.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: "/dashboard/team/member",
    name: "Team-Member",
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/users/member/Member.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: '/dashboard/team/member/edit/:id',
    name: 'ContentEditMember',
    meta: { authorize: [Type[0],Type[3]] },  
    component: () => import('../views/team/dashboard/modules/users/member/EditMember.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: "/dashboard/team/organization",
    name: "Team-Organization",
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/users/organization/Organization.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  /* Payments */
  {
    path: "/dashboard/team/payments",
    name: "Team-Payments",
    meta: { authorize: [Type[0],Type[3]] },
    component: () => import('../views/team/dashboard/modules/payments/Payments.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },


  /******************** Vistas (Organization/Admin) ***************************/
  
  /******************** Vistas (Organization/Manager) ***************************/
  {
    path: "/dashboard/manager",
    name: "Manager-Dashboard",
    meta: { authorize: [Type[2]] },
    component: () => import('../views/manager/dashboard/index.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: '/dashboard/manager/404',
    name: 'Manager-404',
    meta: { authorize: [Type[2]] },    
    component: () => import('../views/manager/dashboard/modules/404/404.vue')
  },
  {
    path: '/dashboard/manager/upgrade',
    name: 'Manager-Upgrade',
    meta: { authorize: [Type[2]] },    
    component: () => import('../views/manager/dashboard/modules/upgrade/Upgrade.vue')
  },
  {
    path: "/dashboard/manager/report",
    name: "Manager-Report",
    meta: { authorize: [Type[2]] },
    component: () => import('../views/manager/dashboard/modules/report/Report.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: "/dashboard/manager/report/time",
    name: "Manager-Time",
    meta: { authorize: [Type[2]] },
    component: () => import('../views/manager/dashboard/modules/report/time/Time.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: "/dashboard/manager/report/appurl",
    name: "Manager-AppUrl",
    meta: { authorize: [Type[2]] },
    component: () => import('../views/manager/dashboard/modules/report/appurl/AppUrl.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: "/dashboard/manager/report/weekly",
    name: "Manager-Weekly",
    meta: { authorize: [Type[2]] },
    component: () => import('../views/manager/dashboard/modules/report/weekly/Weekly.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  /* Project */
  {
    path: '/dashboard/manager/project',
    name: 'Manager-Project',
    meta: { authorize: [Type[2]] },  
    component: () => import('../views/manager/dashboard/modules/project/Project.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  /* End Project */

  /* ToDo*/
  {
    path: '/dashboard/manager/todo',
    name: 'Manager-ToDo',
    meta: { authorize: [Type[2]] },
    component: () => import('../views/manager/dashboard/modules/to-do/ToDo.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: '/dashboard/manager/todo/edit/:id',
    name: 'Manager-ContentEditTodo',
    meta: { authorize: [Type[2]] },  
    component: () => import('../views/manager/dashboard/modules/to-do/EditTodo.vue'),
    beforeEnter: [ifAuth,BlockModule]
  }, 
  /* TimeSheet*/
  {
    path: '/dashboard/manager/timesheet',
    name: 'Manager-TimeSheet',
    meta: { authorize: [Type[2]] },
    component: () => import('../views/manager/dashboard/modules/timesheet/TimeSheet.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
   /* Profile*/
   {
    path: '/dashboard/manager/setting/profile',
    name: 'Manager-Profile',
    meta: { authorize: [Type[2]] },
    component: () => import('../views/manager/dashboard/modules/setting/profile/Profile.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },

  /* Screenshoot*/ 
  {
    path: '/dashboard/manager/screenshot',
    name: 'Manager-Screenshot',
    meta: { authorize: [Type[2]] },
    component: () => import('../views/manager/dashboard/modules/activity/screenshot/Screenshot.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  
  /* Url */
  {
    path: '/dashboard/manager/url',
    name: 'Manager-Url',
    meta: { authorize: [Type[2]] },
    component: () => import('../views/manager/dashboard/modules/activity/url/Url.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  /* App */
  {
    path: '/dashboard/manager/app',
    name: 'Manager-App',
    meta: { authorize: [Type[2]] },
    component: () => import('../views/manager/dashboard/modules/activity/app/App.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },

  /******************** Vistas (Organization/Manager) ***************************/

  /******************** Vistas (Worker) ***************************/

  {
    path: "/dashboard/worker",
    name: "Worker-Dashboard",
    meta: { authorize: [Type[1]] },
    component: () => import('../views/worker/dashboard/index.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: '/dashboard/worker/404',
    name: 'Worker-404',
    meta: { authorize: [Type[1]] },    
    component: () => import('../views/worker/dashboard/modules/404/404.vue')
  },
  {
    path: '/dashboard/worker/upgrade',
    name: 'Worker-Upgrade',
    meta: { authorize: [Type[1]] },    
    component: () => import('../views/worker/dashboard/modules/upgrade/Upgrade.vue')
  },
  {
    path: '/dashboard/worker/member',
    name: 'Worker-Member',
    meta: { authorize: [Type[1]] },    
    component: () => import('../views/worker/dashboard/modules/users/member/Member.vue'),
    beforeEnter: [ifAuth,BlockModule],
  },
  {
    path: '/dashboard/worker/organization',
    name: 'Worker-Organization',
    meta: { authorize: [Type[1]] },    
    component: () => import('../views/worker/dashboard/modules/users/organization/Organization.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: '/dashboard/worker/addorganization',
    name: 'Worker-AddOrganization',
    meta: { authorize: [Type[1]] },    
    component: () => import('../views/worker/dashboard/modules/users/organization/AddOrganization.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: '/dashboard/worker/addmember',
    name: 'Worker-AddMember',
    meta: { authorize: [Type[1]] },    
    component: () => import('../views/worker/dashboard/modules/users/member/AddMember.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: '/dashboard/worker/editmember',
    name: 'Worker-EditMember',
    meta: { authorize: [Type[1]] },   
    component: () => import('../views/worker/dashboard/modules/users/member/EditMember.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  /* teams o equipos de trabajo*/
  {
    path: '/dashboard/worker/team',
    name: 'Worker-Team',
    meta: { authorize: [Type[1]] },   
    component: () => import('../views/worker/dashboard/modules/team/Team.vue'),
    beforeEnter: [ifAuth,BlockModule]
  }, 
  /* end team */
  /* Project */
  {
    path: '/dashboard/worker/project',
    name: 'Worker-Project',
    meta: { authorize: [Type[1]] },  
    component: () => import('../views/worker/dashboard/modules/project/Project.vue'),
    beforeEnter: [ifAuth,BlockModule]
  }, 
  {
    path: '/dashboard/worker/addproject',
    name: 'Worker-AddProject',
    meta: { authorize: [Type[1]] },    
    component: () => import('../views/worker/dashboard/modules/project/AddProject.vue'),
    beforeEnter: [ifAuth,BlockModule]
  }, 
  /* End Project */
  
  /* ToDo*/
  {
    path: '/dashboard/worker/todo',
    name: 'Worker-ToDo',
    meta: { authorize: [Type[1]] },
    component: () => import('../views/worker/dashboard/modules/to-do/ToDo.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: '/dashboard/worker/todo/edit/:id',
    name: 'Worker-ContentEditTodo',
    meta: { authorize: [Type[1]] },  
    component: () => import('../views/worker/dashboard/modules/to-do/EditTodo.vue'),
    beforeEnter: [ifAuth,BlockModule]
  }, 

  /* TimeSheet*/
  {
    path: '/dashboard/worker/timesheet',
    name: 'Worker-TimeSheet',
    meta: { authorize: [Type[1]] },
    component: () => import('../views/worker/dashboard/modules/timesheet/TimeSheet.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  /* Report*/
  {
    path: '/dashboard/worker/report',
    name: 'Worker-Report',
    meta: { authorize: [Type[1]] },
    component: () => import('../views/worker/dashboard/modules/report/Report.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: "/dashboard/worker/report/time",
    name: "Worker-Time",
    meta: { authorize: [Type[1]] },
    component: () => import('../views/worker/dashboard/modules/report/time/Time.vue'),
    beforeEnter: [ifAuth,BlockModule]
  }, 
  {
    path: "/dashboard/worker/report/weekly",
    name: "Worker-Weekly",
    meta: { authorize: [Type[1]] },
    component: () => import('../views/worker/dashboard/modules/report/weekly/Weekly.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },  
  {
    path: "/dashboard/worker/report/appurl",
    name: "Worker-AppUrl",
    meta: { authorize: [Type[1]] },
    component: () => import('../views/worker/dashboard/modules/report/appurl/AppUrl.vue'),
    beforeEnter: [ifAuth, BlockModule]
  },
  {
    path: "/dashboard/worker/report/payments",
    name: "Worker-Payments",
    meta: { authorize: [Type[1]] },
    component: () => import('../views/worker/dashboard/modules/report/payments/Payments.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: "/dashboard/worker/report/amounts",
    name: "Worker-ReportAmountsOwend",
    meta: { authorize: [Type[1]] },
    component: () => import('../views/worker/dashboard/modules/report/payments/Amounts.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  /* Url */
  {
    path: '/dashboard/worker/url',
    name: 'Worker-Url',
    meta: { authorize: [Type[1]] },
    component: () => import('../views/worker/dashboard/modules/activity/url/Url.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  /* App */
  {
    path: '/dashboard/worker/app',
    name: 'Worker-App',
    meta: { authorize: [Type[1]] },
    component: () => import('../views/worker/dashboard/modules/activity/app/App.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  /* Screenshot */
  {
    path: '/dashboard/worker/screenshot',
    name: 'Worker-Screenshot',
    meta: { authorize: [Type[1]] },
    component: () => import('../views/worker/dashboard/modules/activity/screenshot/Screenshot.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },

  {
    path: "/dashboard/worker/member",
    name: "Worker-Member",
    meta: { authorize: [Type[1]] },
    component: () => import('../views/worker/dashboard/modules/users/member/Member.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  {
    path: "/dashboard/worker/organization",
    name: "Worker-Organization",
    meta: { authorize: [Type[1]] },
    component: () => import('../views/worker/dashboard/modules/users/organization/Organization.vue'),
    beforeEnter: [ifAuth,BlockModule]
  },
  /* Profile*/
  {
    path: '/dashboard/worker/setting/profile',
    name: 'Worker-Profile',
    meta: { authorize: [Type[1]] },
    component: () => import('../views/worker/dashboard/modules/setting/profile/Profile.vue'),
    beforeEnter: [ifAuth,BlockModule]
    },

 
  
  /******************** Vistas (Worker) ***************************/

  /******************** Login ***************************/

  {

    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/login/Login.vue'),
    beforeEnter: [ifNotAuth]
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/login/Signup.vue'),
    beforeEnter: [ifNotAuth]
  },
  {
    path: '/signup/confirm',
    name: 'Signup Confirm',
    component: () => import('../views/login/SignupConfirm.vue'),
    beforeEnter: [ifNotAuth]
  },
  //Fogot Password
  {
    path: '/forgot',
    name: 'Forgot password',
    component: () => import('../views/login/Forgot.vue'),
    beforeEnter: [ifNotAuth]
  },
  //Reset Password
  {
    path: '/reset/:code',
    name: 'ResetPassword',
    component: () => import('../views/login/Reset.vue'),
    beforeEnter: [ifNotAuth]
  },
  {
    path: '/login/:redirect/:token',
    name: 'SetToken',    
    component: () => import('../views/login/SetToken.vue'),

  },

  {
    path: '/login/validate',
    name: 'LoginToken',    
    component: () => import('../views/login/LoginToken.vue'),
  },
  {
    path: '/login/subscription/:id',
    name: 'Subscription',    
    component: () => import('../views/login/Subscriptions.vue'),
    beforeEnter: [ifNotAuth]
  }


  /******************** Login ***************************/

]

const router = createRouter
({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve((to, from, next) => 
{
  // If this isn't an initial page load.
  if (to.name) 
  {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => 
{
  // Complete the animation of the route progress bar.
  NProgress.done()
})

router.beforeEach((to, from, next) => 
{
  if (store.state.Authenticated == true) 
  {

    Api.get("/login/expired").then((result) => 
    {
      if(result.data.revoked == "true")
      {
        store.commit("setAuthentication", false);
        store.commit("setTypeUser", "");
        store.commit("setToken", "");
        store.commit("setRefreshToken", "");
        localStorage.clear();
        router.push({ name: "Home" });
        
        /*
        Api.post("/login/refresh", {refresh_token:store.state.RefreshToken}).then((result) => 
        {
          store.commit("setAuthentication", true)
          store.commit("setToken", result.data.token.access_token)
          store.commit("setRefreshToken", result.data.token.refresh_token)
          
      
       })
       */
      }
 
     })
  }

  const { authorize } = to.meta;
  if (authorize) 
  {
    if (authorize.length && !authorize.includes(getRole())) 
    {
      return next(false);
    }
  }
  next();
})


export default router

import {store} from "@/store/store.js";
import { computed } from 'vue'


export const ifAuth = (to, from, next) => 
{
    //console.log("middleware ifAuth", store.state.Authenticated);
    if (store.state.Authenticated == false) 
    {
        next({
            path: "/login"
        });
        // next(false);
    } 
    else next();
};

export const ifNotAuth = (to, from, next) => 
{
    //console.log("middleware ifNotAuth", store.state.Authenticated);
    if (store.state.Authenticated == true) 
    {
      if(store.state.TypeUser=='0') { var prefix = "team" }
      if(store.state.TypeUser=='1') { var prefix = "admin" }
      if(store.state.TypeUser=='2') { var prefix = "worker"}
      if(store.state.TypeUser=='3') { var prefix = "manager"}
      
        next({
            path: "/dashboard/"+ prefix 
        });
    } 
    else next();
}

export const Type = ["Team", "Worker", "Manager", "Team" ]
const TypeUser = computed(() => store.state.TypeUser)

export const getRole = () => 
{
  if (TypeUser.value == 0) 
  {
    return (Type[0])
  } 
  if (TypeUser.value == 2) 
  {
    return (Type[1])
  } 
  if (TypeUser.value == 3) 
  {
    return (Type[2])
  } 
  if (TypeUser.value == 1) 
  {
    return (Type[3])
  } 
 
}

export const BlockModule = (to, from, next) => 
{
     var RouteName = to.name.split('-');
         
     const array = store.state.BlockModules;
     const substring = RouteName[1];
     
     const match = array.find(element => 
      {
       if (element.includes(substring)) 
       {
         if(substring.length == element.length )
         {
          return true;
         }
       }
     });
     
      if (match == RouteName[1]) 
      {
        if(match == undefined)
        {
         next();
        }
        else
        {
          next({
            name: getRole()+'-Upgrade'
          });
        }
      }
      else next();
    
}



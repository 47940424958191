import { computed, watchEffect } from "vue";
import { store } from "@/store/store.js";
import axios from "axios";

const Token = computed(() => store.state.Token)

const Api = axios.create({
  //baseURL: 'http://localhost:8000',
  //baseURL: "https://ex8fkdh2oa.execute-api.us-east-2.amazonaws.com",
  baseURL: process.env.VUE_APP_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    "Authorization": "Bearer " + Token.value,
  },
});

watchEffect(() => 
{
  Api.defaults.headers.Authorization = "Bearer " + Token.value
});

export default Api;


